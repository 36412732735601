
export default {
  getUserList (state, polay) {
    this.state.userList = polay
  },
  getChildrenInfo (state, polay) {
    this.state.childrenInfo = polay
  },
  getChartDateInfo (state, polay) {
    this.state.chartDateInfo = polay
  },
  getOneTableResult (state, polay) {
    this.state.oneTableResult = polay
  },
  getTwoTableResult (state, polay) {
    this.state.twoTableResult = polay
  },
  getThreeableResult (state, polay) {
    this.state.threeTableResult = polay
  }
}
