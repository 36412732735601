// import vue from 'vue'
import router from '@/router'
import { clearLoginInfo, _session } from '@/utils'

import axios from 'axios'
// import Cookies from 'js-cookie'
import isPlainObject from 'lodash/isPlainObject'
import qs from 'qs'
// import config from '../../config/sysConfig'
const http = axios.create({
  baseURL: window.SITE_CONFIG.apiURL,
  timeout: 10000
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  config.headers.token = _session.get('token') || ''
  config.headers.type = 3
  config.headers.Accept = '*/*'
  // if (config.url.startsWith('/login')) {
  //   config.baseURL = window.SITE_CONFIG.loginApiURL
  // } else {
  //   config.baseURL = window.SITE_CONFIG.apiURL
  // }
  // 默认参数
  const defaults = {}
  // 防止缓存,GET 请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ _t: new Date().getTime() }
    }
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
    config.data = qs.stringify(config.data)
  }
  return config
}, err => {
  return err
})
/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data.code === 9990) {
    clearLoginInfo()
    router.replace({ name: 'login' })
    return Promise.reject(response.data.message)
  }
  const res = {
    code: response.data.code,
    msg: response.data.message,
    data: response.data.data['error-info']
  }
  // if (response && response.data && response.data['error-info']) {
  //   tempData.data = response.data.data['error-info']
  // } else {
  //   tempData.data = response.data.data
  // }
  return res
}, err => {
  return err
})
export default http
