// import Cookies from 'js-cookie'
// import store from '@/store'
import moment from 'moment'
/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  // store.commit('resetStore')
  // Cookies.remove('token')
  // window.sessionStorage.removeItem('token')
  window.sessionStorage.clear()
}
/**
 * sessionStorage
 * @调用：_session.set('access_token', '123456', 5000);
 * @调用：_session.get('access_token');
 */
export const _session = {
  // 存储,可设置过期时间
  set (key, value, expires = parseInt(1000 * 60 * 60 * 8)) {
    const params = { key, value, expires }
    if (expires) {
      // 记录何时将值存入缓存，毫秒级
      var data = Object.assign(params, { startTime: new Date().getTime() })
      sessionStorage.setItem(key, JSON.stringify(data))
    } else {
      if (Object.prototype.toString.call(value) === '[object Object]') {
        value = JSON.stringify(value)
      }
      if (Object.prototype.toString.call(value) === '[object Array]') {
        value = JSON.stringify(value)
      }
      sessionStorage.setItem(key, value)
    }
  },
  // 取出
  get (key) {
    let item = sessionStorage.getItem(key)
    // 先将拿到的试着进行json转为对象的形式
    try {
      item = JSON.parse(item)
    } catch (error) {
      // eslint-disable-next-line no-self-assign
      item = item
    }
    // 如果有startTime的值，说明设置了失效时间
    if (item && item.startTime) {
      const date = new Date().getTime()
      // 如果大于就是过期了，如果小于或等于就还没过期
      if (date - item.startTime > item.expires) {
        sessionStorage.removeItem(key)
        return false
      } else {
        return item.value
      }
    } else {
      return item
    }
  },
  // 删除
  remove (key) {
    sessionStorage.removeItem(key)
  },
  // 清除全部
  clear () {
    sessionStorage.clear()
  }
}
// moment时间转为时间戳
export function timeTostamp (v, formatStr = 'YYYY-MM-DD') {
  if (v) {
    return parseInt(moment(moment(v || new Date()).format(formatStr)).valueOf() / 1000)
  }
  return ''
}
// 时间戳转为时间字符串
export function stampToTime (v, formatStr = 'YYYY-MM-DD') {
  if (!v) {
    return ''
  }
  if (v && v === '0') {
    return ''
  }
  return moment((v || 0) * 1000).format(formatStr)
}
