
import { _session } from '@/utils'
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('views/login.vue')
  },
  {
    path: '/mainBox',
    name: 'mainBox',
    meta: {
      title: '首页'
    },
    component: () => import('../views/main.vue'),
    children: [{
      path: '/home',
      name: 'home',
      meta: {
        title: '首页'
      },
      component: () => import('views/home/index.vue')
    },
    {
      path: '/evaluating',
      name: 'evaluating',
      meta: {
        title: '测评系统'
      },
      component: () => import('views/evaluating/index.vue')
    },
    {
      path: '/evaluatingDetail',
      name: 'evaluatingDetail',
      meta: {
        title: '详情'
      },
      component: () => import('views/evaluatingDetail/index.vue')
    }
    ]
  },
  {
    path: '/previewLayout',
    name: 'previewLayout',
    meta: {
      title: '预览'
    },
    component: () => import('views/previewLayout/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})
// console.log('routes', pageRoutes.concat(moduleRoutes))
router.beforeEach((to, from, next) => {
  const tokenStr = _session.get('token')
  console.log(to)
  document.title = 'VB-MAPP评估-' + to.meta.title
  if (to.name !== 'login' && !tokenStr) {
    next({ name: 'login' })
  }
  next()
})
router.afterEach()

export default router
