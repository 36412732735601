import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    userList: {},
    childrenInfo: {},
    // lineChartDateInfo: [],
    chartDateInfo: [],
    oneTableResult: [],
    twoTableResult: [],
    threeTableResult: []
  },
  mutations,
  actions,
  modules
})
