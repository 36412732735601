<template>
    <a-date-picker :disabled="disabled" v-model="picValue" @change="onChange" :placeholder="placeholder" :disabled-date="disabledDate" />
</template>

<script>
import moment from 'moment'
import { timeTostamp } from 'utils'
export default {
  name: 'VbDatePicker',
  props: {
    value: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    width: {
      type: String,
      default: () => {
        return ''
      }
    },
    valueFormat: {
      type: String,
      default: () => {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disDate: {
      type: String,
      default: ''
    }

  },
  data () {
    return {
      picValue: null
    }
  },
  computed: {

  },
  watch: {
    value (n) {
      console.log('n', n)
      if (n) {
        this.picValue = moment(n * 1000).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.picValue = null
      }

      console.log('  this.picValue', this.picValue)
    }
  },
  methods: {
    moment: moment,
    onChange (e) {
      this.picValue = e
      this.$emit('input', timeTostamp(e, this.valueFormat ? this.valueFormat
        : 'YYYY-MM-DD HH:mm:ss'))
      this.$emit('change', timeTostamp(e, this.valueFormat ? this.valueFormat
        : 'YYYY-MM-DD HH:mm:ss'))
    },
    disabledDate (cur) {
      if (this.disDate === 'newDate') {
        return cur > moment().endOf('day') // 未来时间不可选
      } else if (this.disDate === 'oldDate') {
        return cur && cur < moment().endOf('day') // 当前及过去时间不可选
      } else {
        return '' // 默认
      }
    }
  }
}
</script>
